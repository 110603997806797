import type { Media, MediaTag } from "~/types";

//Tag 'default' is not used in the application, but in case you want to refresh without knowing the tag, you can use this tag.
export const useMediaLibrary = (tag: MediaTag = "general") => {
  const refreshing = useState("mediaLibraryRefreshing", () => false);
  const mediaLibrary = useState("companyMedia", () => [] as Media[]);
  const { selectedCompany } = useWerkgelukUser();

  const refresh = async () => {
    if (refreshing.value) return;
    log(`Refreshing media library for company ${selectedCompany.value?.id}`);
    refreshing.value = true;
    const client = useStrapiClient();
    mediaLibrary.value = await client<Media[]>(
      `company/${selectedCompany.value?.id}/media`,
    );
    refreshing.value = false;
  };

  if (mediaLibrary.value.length === 0) {
    refresh();
  }

  //expects a list of files from 'formkit file' and a metadata object.
  const uploadMedia = async (
    files: { name: string; file: File }[],
    metaData: unknown,
  ) => {
    if (!allowUpload.value) {
      throw new Error("Upload limit reached.");
    }
    const client = useStrapiClient();

    const postData = new FormData();

    //append CV
    files.forEach((file) => {
      //check if file is too big (1MB)
      const exceedsSize = file.file.size > 1024 * 1024;
      if (!exceedsSize) {
        postData.append(`files.file`, file.file, file.file.name);
      } else {
        throw new Error(
          `File is too big. Max size is 1MB, selected file is ${(file.file.size / 1024 / 1024).toFixed(2)}MB`,
        );
      }
    });

    postData.append("fileInfo", JSON.stringify(metaData));

    //upload the file
    const result = await client(`/company/${selectedCompany.value?.id}/media`, {
      method: "POST",
      body: postData,
    });
    await refresh();
    return result;
  };

  const deleteMedia = async (id: number) => {
    const client = useStrapiClient();
    await client(`/company/${selectedCompany.value?.id}/media/${id}`, {
      method: "DELETE",
    });
    await refresh();
  };

  /*
   * all the files in the media library with the given tag
   */
  const files = computed<Media[]>(() =>
    mediaLibrary.value.filter((m) => m.tag === tag),
  );

  const maxAmount = computed(() => {
    switch (tag) {
      case "logo":
        return 1;
      case "header":
        return 3;
      case "slider":
        return 3;
      case "general":
        return 3;
    }
  });
  const allowUpload = computed(
    () => customFiles.value.length < maxAmount.value,
  );

  /*
   * all the files uploaded by the company
   */
  const customFiles = computed(() =>
    files.value.filter((f) => f.company?.id == selectedCompany.value?.id),
  );
  /*
   * all shared files (uploaded by WerkGeluk)
   */
  const sharedFiles = computed(() => files.value.filter((f) => !f.company));

  return {
    files,
    customFiles,
    sharedFiles,
    refresh,
    uploadMedia,
    deleteMedia,
    maxAmount,
    allowUpload,
  };
};
